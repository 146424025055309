/* eslint-disable react/display-name */
import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { H1, H2, H3, H4, H5, H6, P, B } from './styles.js'
import { OpaqueButton } from '../../components/Button'
import Modal from '../../components/Modal'

import Icon from '../../components/Icon'

const options = theme => ({
  renderText: text =>
    text
      .split('\n')
      .reduce(
        (children, textSegment, index) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        []
      ),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        // children && ((children[0][1] !== "") || (children.length >= 2)) &&
        <P
          color={`${theme}.text`}
          fontSize={[0]}
          fontFamily="headings"
          fontWeight={300}
          // w={[1]}
        >
          {children}
        </P>
      )
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <H1
        color={`${theme}.text`}
        fontSize={[3, 2, 2, 2]}
        w={[1]}
        fontFamily="body"
        fontWeight={400}
        lineHeight={[0]}
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <H2
        color={theme === 'dark' ? `${theme}.text` : `${theme}.textAlt`}
        fontSize={[4, 3, 3, 3, 3, 3, 3]}
        fontFamily="headings"
        lineHeight={1.4}
        fontWeight={theme === 'dark' ? 400 : 300}
        letterSpacing="0.23px"
        textShadow={theme === 'dark' && 'text'}
      >
        {children}
      </H2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <H3 color={`${theme}.text`} fontSize={[6, 5, 5, 5, 5, 5, 5]} w={[1]}>
        {children}
      </H3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <H4 color={`${theme}.text`} fontSize={[6, 5, 5, 5, 5, 5, 5]} w={[1]}>
        {children}
      </H4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6>{children}</H6>,
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (node, children) => <>{children}</>,
    [BLOCKS.HR]: () => <br />,
    [INLINES.HYPERLINK]: (node, children) => (
      <OpaqueButton theme={theme} href={node.data.uri}>
        {children}
      </OpaqueButton>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <OpaqueButton
        theme={theme}
        to={node.data.target ? node.data.target.fields.slug['en-US'] : ''}
        href={node.data.uri}
      >
        {children}
      </OpaqueButton>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const findModal =
        node &&
        node.data &&
        node.data.target &&
        node.data.target.fields &&
        node.data.target.fields.mediaReference
      const modalContent = node && node.data && node.data.target
      if (findModal) {
        return (
          <Modal type="opaque" media={modalContent}>
            {children}
          </Modal>
        )
      }
      return (
        <OpaqueButton
          theme={theme}
          to={node.data.target ? node.data.target.fields.slug['en-US'] : ''}
          href={node.data.uri}
        >
          {children}
        </OpaqueButton>
      )
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      if (node.data.target.fields) {
        let { title, icon } = node.data.target.fields
        title = title['en-US']
        icon = icon['en-US']
        return <Icon icon={icon} title={title} />
      } else {
        return <Icon icon="Support" title="Support" />
      }
    },
  },
  renderMark: {
    [MARKS.BOLD]: text => (
      <B color={`${theme}.textAlt`} fontWeight={400}>
        {text}
      </B>
    ),
    [MARKS.ITALIC]: text => <>{text}</>,
    [MARKS.UNDERLINE]: text => <>{text}</>,
  },
})

export const renderRichText = (document, theme) =>
  documentToReactComponents(document, options(theme))
