import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BladeApolloCPAContainer } from '../BladeContainer'
import { PreviewAssetsContext } from '../../context'
import usePreviewQuery, {
  GET_ENTRY,
  GET_ALL_ASSETS,
} from '../../hooks/usePreviewQuery'
import { filterLinkedIds, filterAssets } from '../../utils'
import { TimelineEntryApolloCPAContainer } from '../TimelineEntryContainer'
import { NewsDetailEntryApolloCPAContainer } from '../NewsDetailEntryContainer'

const renderPreviewForContentType = ({ contentTypeId, bladeIds, entry }) => {
  switch (contentTypeId) {
    case 'teamMember':
      return <div></div>
    case 'project':
      return (
        <>
          {bladeIds.length >= 1 &&
            bladeIds.map((item, i) => (
              <BladeApolloCPAContainer key={i} id={item.id} order={i + 1} />
            ))}
          <TimelineEntryApolloCPAContainer
            ids={entry?.fields?.timelineEntries
              ?.map(item => item.sys.id)
              .join(',')}
          />
        </>
      )
    case 'news':
      return (
        <NewsDetailEntryApolloCPAContainer {...entry?.fields} id={entry?.id} />
      )
    default:
      return <div />
  }
}

const Preview = ({ id, contentTypeId }) => {
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRY,
    id: id,
    skip: !id,
  })
  const {
    loading: loadingAssets,
    error: errorAssets,
    data: dataAssets,
  } = usePreviewQuery({
    query: GET_ALL_ASSETS,
  })

  const [bladeIds, setBladeIds] = useState([])
  const [assets, setAssets] = useState([])

  useEffect(() => {
    if (!loading && !error && data) {
      const bIds = filterLinkedIds({ dataObj: data.entry, key: 'blades' })
      setBladeIds(bIds)
    }
  }, [loading, data, error])

  useEffect(() => {
    if (!loadingAssets && !errorAssets && dataAssets) {
      const assets = filterAssets({ assetsArr: dataAssets.assets.items })
      setAssets(assets)
    }
  }, [loadingAssets, dataAssets, errorAssets])

  if (contentTypeId || data?.entry?.contentType !== 'page') {
    return (
      <PreviewAssetsContext.Provider value={{ assets }}>
        {renderPreviewForContentType({
          contentTypeId: contentTypeId || data?.entry?.contentType,
          bladeIds,
          entry: data?.entry,
        })}
      </PreviewAssetsContext.Provider>
    )
  }
  return (
    <PreviewAssetsContext.Provider value={{ assets }}>
      {bladeIds.length >= 1 &&
        bladeIds.map((item, i) => (
          <BladeApolloCPAContainer key={i} id={item.id} order={i + 1} />
        ))}
    </PreviewAssetsContext.Provider>
  )
}

Preview.propTypes = {}

export default Preview
