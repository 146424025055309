import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledBreadContainer,
  StyledLinkContainer,
  StyledLink,
  StyledArrow,
  StyledLinkLong,
} from './styles'

import { navigate } from 'gatsby'

const Breadcrumb = ({
  location,
  currentTitle,
  separator = ' / ',
  short = false,
  theme = 'light',
  hidden = false,
}) => {
  const currentTrail =
    location?.state?.trail && location.state.trail.length !== 0
      ? location.state.trail
      : [{ slug: '/', title: 'Home' }]

  const currentTrailLong = location
    ? currentTrail.concat([{ slug: location.pathname, title: currentTitle }])
    : currentTrail

  const currentIndex = currentTrail?.length - 1
  return (
    <StyledBreadContainer
      display={hidden ? 'none' : 'block'}
      color={`${theme}.textAlt`}
    >
      {short && currentTrail ? (
        <StyledLinkContainer
          display="flex"
          flexDirection="row"
          alignItems="center"
          pl={1}
        >
          <StyledArrow />
          <StyledLink
            fontFamily="body"
            onClick={() => {
              const current = currentTrail.pop()
              navigate(current.slug, {
                state: { trail: currentTrail },
              })
            }}
          >
            {currentTrail[currentIndex]?.title}
          </StyledLink>
        </StyledLinkContainer>
      ) : (
        currentTrailLong?.map((local, index) => (
          <StyledLinkContainer key={`${local.title}`} display="inline">
            <StyledLinkLong
              to={local.slug}
              state={{ trail: currentTrailLong.slice(0, index) }}
            >
              {local.title}
            </StyledLinkLong>
            {separator}
          </StyledLinkContainer>
        ))
      )}
    </StyledBreadContainer>
  )
}

Breadcrumb.propTypes = {
  location: PropTypes.object,
  currentTitle: PropTypes.string,
  separator: PropTypes.string,
  short: PropTypes.bool,
  theme: PropTypes.string,
  hidden: PropTypes.bool,
}

export default Breadcrumb
