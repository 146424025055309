import styled from 'styled-components'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
  border,
  shadow,
} from 'styled-system'

export const Container = styled.div`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`
export const StyledCardContainer = styled(Container)``

export const StyledTest = styled.p`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`

export const StyledNewsContentContainer = styled(Container)``

export const StyledNewsEntryWrapper = styled(Container)``

export const StyledWidthContainer = styled(Container)`
  box-sizing: border-box;
`
export const StyledTitle = styled.p`
  ${space}    
  ${layout}
  ${typography}
  ${color}
`

export const StyledPlaceholderBreadcrumb = styled(Link)`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`

export const StyledButtonContainer = styled(Container)`
  cursor: pointer;
`
export const StyledCategoryDateContainer = styled(Container)``

export const StyledDetailsContainer = styled(Container)``

export const StyledCategory = styled.p`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`
export const StyledImageContainer = styled(Container)``

export const StyledImage = styled(BackgroundImage)`
${position}
${flexbox}
${space}
${color}
${layout}
${typography}
${border}
`
export const StyledBody = styled.p`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${border}
`
export const StyledRichTextContainer = styled(Container)``

export const StyledBorder = styled(Container)``

export const H1 = styled.h1`
${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H2 = styled.h2`
${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H3 = styled.h3`
${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H4 = styled.h4`
${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H5 = styled.h5`
${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const H6 = styled.h6`
${color}
  ${layout}
  ${typography}
  ${shadow}

`
export const P = styled.p`
${color}
  ${layout}
  ${typography}

  ${shadow}
`

export const B = styled.b`
${color}
  ${layout}
  ${typography}

`
export const StyledIntro = styled.p`
${position}
${flexbox}
${space}
${color}
${layout}
${typography}
${border}
`
export const StyledList = styled.ul`
  padding: 0;
  display: flex;
  flex-flow: wrap;
  ${position}
  ${flexbox}
  ${layout}
  ${color}
  ${typography}
  ${space}
  ${flexbox}

  li:last-child a span {
    border-right: none;
  }
`
export const StyledListItem = styled.li`
  list-style: none;
  display: flex;
  flex-flow: wrap;
  ${layout}
  ${color}
  ${typography}
  ${space}
  ${flexbox}
  ${position}
`
export const StyledIcon = styled.img`
  ${space}
`

export const StyledMediaIcon = styled(StyledIcon)`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  @keyframes filter-animation {
    25% {
      filter: sepia(100%) invert(100%) brightness(2.1);
    }
    50% {
      filter: sepia(100%) invert(100%) brightness(2.5);
    }
    75% {
      filter: sepia(100%) invert(100%) brightness(2.5);
    }
    100% {
      filter: sepia(100%) invert(100%) brightness(2.5);
    }
  }

  &:hover {
    animation: filter-animation 1.75s;
    animation-fill-mode: forwards;
  }

  ${({ changeCursor }) =>
    changeCursor ? `cursor: default;` : `cursor: pointer; `}

  ${position}
`
export const StyledSocialLink = styled.a`
  ${space}
`
export const StyledCopyLink = styled.div`
  ${space}
  ${position}
`

export const StyledPopUp = styled(Container)`
  overflow: hidden;
  white-space: nowrap;
`

export const StyledPopUpContainer = styled(Container)`
  transition: all 0.3s;
  box-shadow: 0px 4px 8px 0 rgba(0, 0, 0, 0.1);
  ${({ visibile }) =>
    visibile ? `visibility: visibile;` : `visibility: hidden; `}
`
