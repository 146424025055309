import styled from 'styled-components'
import { Link } from 'gatsby'

import {
  position,
  flexbox,
  space,
  color,
  layout,
  typography,
} from 'styled-system'

export const Container = styled.div`
  ${position}
  ${flexbox}
  ${space}
  ${color}
  ${layout}
  ${typography}
`
export const StyledBreadContainer = styled(Container)``

export const StyledLinkContainer = styled(Container)``

export const StyledArrow = styled(Container)`
  width: 10px;
  height: 10px;
  border-right: 2px solid;
  border-top: 2px solid;
  transform: rotate(-135deg);
`

export const StyledLink = styled(Container)`
  cursor: pointer;
`
export const StyledLinkLong = styled(Link)``
