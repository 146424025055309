import fb from '../../../assets/fb-icon@3x.png'
import ig from '../../../assets/ig-icon@3x.png'
import tw from '../../../assets/tw-icon@3x.png'
import li from '../../../assets/post-link@3x.png'

const links = require('../../../../coh/config/index')

export default [
  {
    id: '1',
    url: links.fbLink,
    src: fb,
    alt: 'fb',
  },
  {
    id: '2',
    url: links.twitterLink,
    src: tw,
    alt: 'tw',
  },
  {
    id: '3',
    url: null,
    src: li,
    alt: 'li',
  },
]
