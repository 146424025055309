import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PostBody, PostTitle, PostRelated } from '../../sections/Post'
import { PreviewAssetsContext } from '../../context'
import usePreviewQuery, {
  GET_ENTRIES_BY_CONTENT_TYPE_ID,
} from '../../hooks/usePreviewQuery'
import { filterAssetsObjFromAssetsArrByIdAndReturnFluid } from '../../utils'

const NewsDetailEntryApolloCPAContainer = ({
  title,
  intro,
  date,
  categories,
  heroImage,
  bodyCopy,
  id,
}) => {
  const { assets } = useContext(PreviewAssetsContext)
  const hero = filterAssetsObjFromAssetsArrByIdAndReturnFluid({
    id: heroImage?.sys?.id,
    assetsArr: assets,
  })
  const { loading, error, data } = usePreviewQuery({
    query: GET_ENTRIES_BY_CONTENT_TYPE_ID,
    id: 'news',
    skip: !id,
  })
  const [relatedNews, setRelatedNews] = useState([])

  useEffect(() => {
    if (!loading && !error && data) {
      let rNews = data?.entries?.items?.map(({ sys, fields }) => ({
        date: fields.date,
        title: fields.title,
        heroImage: filterAssetsObjFromAssetsArrByIdAndReturnFluid({
          id: fields?.heroImage?.sys?.id,
          assetsArr: assets,
        }),
        intro: fields.intro,
        slug: fields.slug,
        parentSlug: 'parent',
        location: 'loc',
        categories: fields.categories,
        id: sys.id,
      }))
      setRelatedNews(rNews)
    }
  }, [data, loading, error, assets])

  return (
    <>
      <PostTitle
        title={title}
        intro={intro}
        date={date}
        categories={categories}
        heroImage={hero}
        location={location}
      />
      <PostBody body={bodyCopy} />
      {!loading && data.entries && relatedNews.length > 1 && (
        <PostRelated
          news={relatedNews}
          // pageTitle={title}
          location={location}
          // parent={parentSlug}
          filterId={id}
        />
      )}
    </>
  )
}

NewsDetailEntryApolloCPAContainer.propTypes = {}

export default NewsDetailEntryApolloCPAContainer
