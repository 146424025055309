import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledNewsEntryWrapper,
  StyledWidthContainer,
  StyledTitle,
  StyledDetailsContainer,
  StyledCategoryDateContainer,
  StyledCategory,
  StyledImage,
  StyledIntro,
} from './styles'
import { Date } from '../../components/Date'
import { useMobile, useComponentTheme } from '../../hooks'
import postIconLinks from './__mocks__/postSocialLinks.js'
import SocialMediaIcon from './SocialMediaIcon'
import { Breadcrumb } from '../../components/Breadcrumb'

const PostTitle = ({
  title,
  alt,
  intro,
  date,
  categories,
  heroImage,
  theme = true,
  location,
}) => {
  const { theme: themeStyle } = useComponentTheme(theme)
  const [isMobile] = useMobile()

  return (
    <StyledNewsEntryWrapper
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bg={`${themeStyle}.bg`}
    >
      <StyledWidthContainer
        display="flex"
        maxWidth="maxWidth.xlarge"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        width="100%"
        pt={4}
        px={3}
        minWidth={['0px', '0px', '800px']}
      >
        <Breadcrumb
          location={location}
          currentTitle={title}
          theme={themeStyle}
          short
        />
        {!isMobile && (
          <>
            <StyledTitle
              fontSize={isMobile ? 4 : 2}
              fontWeight="300"
              color="black"
              mt={4}
              mb={0}
            >
              {title}
            </StyledTitle>

            <StyledDetailsContainer
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid"
              borderColor="hero"
            >
              <StyledCategoryDateContainer
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                fontSize={7}
              >
                <StyledCategory pr={[2, 5, 5]}>
                  {categories?.[0]}
                </StyledCategory>
                <Date fontWeight={600} date={date} />
              </StyledCategoryDateContainer>
              <SocialMediaIcon
                socialMediaLinks={postIconLinks}
                location={location}
              />
            </StyledDetailsContainer>
          </>
        )}
        {heroImage && (
          <StyledImage
            fluid={heroImage.fluid}
            alt={alt || title || 'post'}
            width="100%"
            height="350px"
            mt={2}
          />
        )}
        {isMobile && (
          <>
            <StyledTitle
              fontSize={isMobile ? 4 : 2}
              fontWeight="300"
              color="black"
              mt={4}
              mb={0}
            >
              {title}
            </StyledTitle>
            <StyledIntro fontWeight="300" fontSize={7}>
              {intro.intro}
            </StyledIntro>
            <StyledDetailsContainer
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
              borderBottom="1px solid"
              borderColor="hero"
            >
              <StyledCategoryDateContainer
                display="flex"
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                fontSize={7}
              >
                <StyledCategory>{categories?.[0]}</StyledCategory>
                <Date date={date} />
              </StyledCategoryDateContainer>
            </StyledDetailsContainer>
          </>
        )}
      </StyledWidthContainer>
    </StyledNewsEntryWrapper>
  )
}

PostTitle.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  heroImage: PropTypes.object,
  theme: PropTypes.bool,
  location: PropTypes.object,
}

export default PostTitle
