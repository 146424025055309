import React from 'react'
import PropTypes from 'prop-types'
import { renderRichText } from './richText.js'
import { useComponentTheme } from '../../hooks'
import {
  StyledRichTextContainer,
  StyledWidthContainer,
  StyledBorder,
} from './styles'

const PostBody = ({ body, theme = 'true' }) => {
  const { theme: themeStyle } = useComponentTheme(theme)

  return (
    <StyledRichTextContainer
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bg={`${themeStyle}.bg`}
    >
      <StyledWidthContainer
        display="flex"
        flexDirection="column"
        maxWidth="maxWidth.xlarge"
        px={3}
        py={4}
      >
        {renderRichText(body, themeStyle)}
        <StyledBorder
          width="100%"
          borderBottom="1px solid"
          borderColor="hero"
        />
      </StyledWidthContainer>
    </StyledRichTextContainer>
  )
}

PostBody.propTypes = {
  body: PropTypes.object,
  theme: PropTypes.bool,
}

export default PostBody
