import React, { useState } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import {
  StyledList,
  StyledSocialLink,
  StyledMediaIcon,
  StyledListItem,
  StyledCopyLink,
  StyledPopUp,
  StyledPopUpContainer,
} from './styles'

const SocialMediaIcon = ({ socialMediaLinks, maxWidth = '35px', location }) => {
  const [displayTip, setDisplayTip] = useState(false)
  const handleCopyClick = () => {
    copy(location.href)
    setDisplayTip(true)
  }

  if (!socialMediaLinks) return null
  return (
    <StyledList
      flexDirection={'row'}
      my={3}
      justifyContent="center"
      alignItems="center"
    >
      {socialMediaLinks.map(({ id, url, src, alt }) =>
        alt === 'li' ? (
          <StyledListItem key={id} mx={[2, 2, 1]} position="relative">
            <StyledPopUpContainer
              position="absolute"
              top="95%"
              opacity={displayTip ? 1 : 0}
              visibile={displayTip}
              zIndex={100}
              maxWidth="300px"
              bg="white"
            >
              <StyledPopUp
                position="relative"
                m={'5px'}
                fontFamily="body"
                fontWeight={500}
                fontSize="9px"
                zIndex={100}
              >
                Link Copied
              </StyledPopUp>
            </StyledPopUpContainer>

            <StyledCopyLink
              onClick={() => {
                handleCopyClick()
              }}
              onMouseLeave={() => {
                setDisplayTip(false)
              }}
              mx={3}
              my={0}
              fontSize={6}
            >
              <StyledMediaIcon
                width="28px"
                height="auto"
                src={src}
                alt={alt || 'social'}
                maxWidth={maxWidth}
                changeCursor={displayTip}
              />
            </StyledCopyLink>
          </StyledListItem>
        ) : (
          <StyledListItem key={id} mx={[2, 2, 1]}>
            <StyledSocialLink
              href={url}
              mx={3}
              my={0}
              fontSize={6}
              target="__blank"
            >
              <StyledMediaIcon
                src={src}
                alt={alt || 'social'}
                maxWidth={maxWidth}
              />
            </StyledSocialLink>
          </StyledListItem>
        )
      )}
    </StyledList>
  )
}

SocialMediaIcon.propTypes = {
  socialMediaLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string,
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ),
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
}
export default SocialMediaIcon
