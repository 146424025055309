import React from 'react'
import PropTypes from 'prop-types'
import { Cards } from '../../components/Cards'

const PostRelated = ({
  news,
  parent,
  filterId = '',
  theme = true,
  ...rest
}) => {
  let filteredNews = news.filter(item => item.id !== filterId)

  const displayNews = { news: filteredNews, parentSlug: parent }
  return (
    <Cards
      news={displayNews}
      title="Related News"
      theme={theme}
      showBtn={false}
      extend
      limitDisplay
      {...rest}
    />
  )
}

PostRelated.propTypes = {
  news: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.bool,
}

export default PostRelated
