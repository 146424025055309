module.exports = {
  url: 'http://www.cityofhope.com', // do no include trailing backslash
  postsPerHomePage: 7,
  postsPerPage: 6,
  authorDefaultEmail: 'contact@cityofhope.com',
  author: 'City Of Hope', // author for RSS / SEO schema
  authorURL: 'http://localhost:8000', // url for author/publisher schema
  twitterHandle: '@cityofhope', // for twitter cards
  shortTitle: 'City Of Hope', // for app manifest
  image: '/logos/cityofhope.jpg', // OG default share image, 1200 x 1200 recommended
  imageWidth: 1200, // change to actual width of share image
  imageHeight: 1200, // ditto actual height
  logo: 'favicon.png', // logo for SEO, RSS, and app manifest
  logoAlt: 'City Of Hope',
  backgroundColor: '#e9e9e9', // offline manifest
  themeColor: '#121212', // offline manigest
  copyright: 'Copyright © 2020 City Of Hope', // copyright for RSS feed
  publisher: 'City Of Hope',
  shareImage: '/logos/cityofhope.jpg', // OG default share image, 1200 x 1200 recommended
  shareImageWidth: 1200, // change to actual width of share image
  shareImageHeight: 1200, // ditto actual height
  titleAlt: 'City Of Hope',
  title: 'City Of Hope',
  theme: 'blue',
  description: 'City Of Hope',
  twitterLink: 'https://www.twitter.com/cityofhope',
  igLink: 'http://instagram.com/cityofhope',
  fbLink: 'https://www.facebook.com/cityofhope',
  trackingId: 'UA-168560898-1',
}
