import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import ApolloPreview from '../../../common/containers/ApolloCPAContainer'
import { parseQueryString } from '../../../common/utils'
import Redirect from '../../../common/components/Redirect'
import config from '../../config'

/**
 * 
 * a.network PREVIEW LINKS
 * home
 * /preview/?t=anetwork&id=EXhdPipJycEd6NC7GFQhb
 
 * The Players
 * http://localhost:8000/preview/?t=anetwork&id=7lQS580DoBS5SVrnPUHsef
 * grid
 * 
 * Nav bar - last
 * Footer - last
 * 
 * // nested entries / not pages
 * add contentType
 * http://localhost:8000/preview/?t=anetwork&id=2rhnNk05btEGHwuDeUWIQm&contentType=teamMember
 */
const DEFAULT_HOME_PREVIEW_ID = 'EXhdPipJycEd6NC7GFQhb'

const Preview = ({ pageContext }) => {
  const [pageId, setPageId] = useState(DEFAULT_HOME_PREVIEW_ID)
  const [contentTypeId, setContentTypeId] = useState('page')
  const [loading, setLoading] = useState(true)
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const accessToken = parseQueryString('t')
      if (accessToken === process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN) {
        setAuth(true)
        setLoading(false)
      } else {
        setAuth(false)
        setLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && auth) {
      const id = parseQueryString('id')
      setPageId(id)
    }
  }, [auth])

  useEffect(() => {
    if (typeof window !== 'undefined' && auth) {
      const contentTypeId = parseQueryString('contentType')
      setContentTypeId(contentTypeId)
    }
  }, [auth])

  if (loading) {
    return (
      <Layout {...pageContext} pageSEO config={config} theme={config.theme}>
        <div />
      </Layout>
    )
  }
  if (!loading) {
    if (!auth) {
      return <Redirect to="/" />
    } else {
      return (
        <Layout {...pageContext} pageSEO config={config} theme={config.theme}>
          <ApolloPreview id={pageId} contentTypeId={contentTypeId} />
        </Layout>
      )
    }
  }
}

Preview.propTypes = {}

export default Preview
